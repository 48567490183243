module.exports = {
  entry: './src/apps/ns1-roi/index.tsx',
  shortcode: 'ns1-roi',
  title: 'NS1 ROI',
  status: 'stable',
  description: 'Allows users to complete self assessment.',
  preview: {
    url: `${process.env.PUBLIC_URL}/storybook/iframe.html?id=widgets-ns1-roi-components--default&viewMode=story`,
  },
  useExternalPeerDependencies: ['react', 'react-dom', 'react-intl'],
  additionalCustomProperties: {
    webSegmentPaths: ['/products'],
    availableTranslations: [
      'de',
      'en',
      'es',
      'fr',
      'it',
      'ja',
      'ko',
      'pt',
      'zh-cn',
    ],
    org: 'digitalsales', // to filter the widget
    widgetLocation: '/pricing', // default '/pricing' but sometimes '/'
    productName: 'ns1-connect', // the product name in the URL
    activatedCountryPages: ['us-en'],
  },
};
